<template>
  <router-view></router-view>
</template>

<script>
export default {
}
</script>
<style>
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
.btn-group-vertical > .btn:not(:first-child){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
</style>
