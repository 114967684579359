import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import {apiDomain, getHeader } from '@/assets/js/config.js'
import axios from 'axios'

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

async function getUserLoginDetail(to, next) {
  const auth_user = {}
  const loginUser = {}
  auth_user.access_token = to.params.token
  window.localStorage.setItem('authUser', JSON.stringify(auth_user));

  return axios.get(apiDomain + 'setup/user.info', { headers: getHeader() })
    .then(response => {
        loginUser.id = response.data.id
        loginUser.role_id = response.data.role_id
        loginUser.name = response.data.name
        loginUser.email = response.data.email
        window.localStorage.setItem('loginUser', JSON.stringify(loginUser));
      })
    .catch ( error => {
        alert("Your access token is incorrect. Proceed to login page......");
        next({name: 'Login'});
    });
  
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth)
  {
      if(to.params.token)
      {
        await getUserLoginDetail(to, next);
      }

      var authUser = JSON.parse(window.localStorage.getItem('authUser'))
      if ( authUser && authUser.access_token )
      {
          next() 
      }
      else
      {
          next({name: 'Login'})
      }
  }
  next()
})



export default router;
