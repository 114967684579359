import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');
const NestedSorting = () => import(/* webpackChunkName: "components" */ '@/views/Components/NestedSorting.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

// Setup pages
const Users = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/Users.vue');
const AddUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/AddUsers.vue');
const EditUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/EditUsers.vue');
const MyUserProfile = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/UserProfile/MyUserProfile.vue');

const ApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/ApplicationType.vue');
const AddApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/AddApplicationType.vue');
const EditApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/EditApplicationType.vue');

// Category
const Category = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/CategoryFolder/Category.vue');
const AddCategory = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/CategoryFolder/AddCategory.vue');
const EditCategory = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/CategoryFolder/EditCategory.vue');

// Sub Category
const SubCategory = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubCategoryFolder/SubCategory.vue');
const AddSubCategory = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubCategoryFolder/AddSubCategory.vue');
const EditSubCategory = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubCategoryFolder/EditSubCategory.vue');

const QuestionType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/QuestionTypeFolder/QuestionType.vue');
const AddQuestionType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/QuestionTypeFolder/AddQuestionType.vue');
const EditQuestionType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/QuestionTypeFolder/EditQuestionType.vue');

const Frequency = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/FrequencyFolder/Frequency.vue');
const AddFrequency = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/FrequencyFolder/AddFrequency.vue');
const EditFrequency = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/FrequencyFolder/EditFrequency.vue');

const ExternalApplications = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ExternalApplicationFolder/ExternalApplications.vue');
const AddExternalApplications = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ExternalApplicationFolder/AddExternalApplications.vue');
const EditExternalApplications = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ExternalApplicationFolder/EditExternalApplications.vue');


const GlobalTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/GlobalTemplate/GlobalTemplates.vue');
const AddGlobalTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/GlobalTemplate/AddGlobalTemplates.vue');
const EditGlobalTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/GlobalTemplate/EditGlobalTemplates.vue');
const GlobalTemplateQuestions = () => import(/* webpackChunkName: "tables" */ '@/views/GlobalTemplate/GlobalTemplateQuestions.vue');

const MyTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/MyTemplate/MyTemplates.vue');
const AddMyTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/MyTemplate/AddMyTemplates.vue');
const EditMyTemplates = () => import(/* webpackChunkName: "tables" */ '@/views/MyTemplate/EditMyTemplates.vue');
const MyTemplateQuestions = () => import(/* webpackChunkName: "tables" */ '@/views/MyTemplate/MyTemplateQuestions.vue');

const Customers = () => import(/* webpackChunkName: "tables" */ '@/views/Customer/Customers.vue');
const OtherCustomers = () => import(/* webpackChunkName: "tables" */ '@/views/Customer/OtherCustomers.vue');
const AddCustomers = () => import(/* webpackChunkName: "tables" */ '@/views/Customer/AddCustomers.vue');
const EditCustomers = () => import(/* webpackChunkName: "tables" */ '@/views/Customer/EditCustomers.vue');
const SendCustomersForm = () => import(/* webpackChunkName: "tables" */ '@/views/Customer/SendCustomersForm.vue');

const KYCForm = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCForm.vue');
const KYCFormV2 = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormV2.vue');
const KYCFormAuth = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormAuth.vue');
const KYCFormSubmit = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormSubmit.vue');
const KYCFormAnswer = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormAnswer.vue');
const KYCFormAnswerV2 = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormAnswerV2.vue');
const KYCFormAnswerV3 = () => import(/* webpackChunkName: "tables" */ '@/views/KYC/KYCFormAnswerV3withChart.vue');


let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    },
    {
      path: 'nested-sorting',
      name: 'Nested Sorting',
      component: NestedSorting
    }
  ]
};

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

let setupMenu = {
  path: '/setup',
  component: DashboardLayout,
  redirect: '/setup/users',
  name: 'Setup menu',
  children: [
    {
      path: 'users',
      name: 'Users',
      meta : { requiresAuth: true },
      component: Users,
    },
    {
      path: 'addUsers',
      name: 'Add Users',
      meta : { requiresAuth: true },
      component: AddUsers,
    },
    {
      path: 'editUsers/:id',
      name: 'Edit Users',
      meta : { requiresAuth: true },
      component: EditUsers,
    },
    {
      path: 'my_profile',
      name: 'My Profile',
      meta : { requiresAuth: true },
      component: MyUserProfile,
    },
    {
      path: 'question_type',
      name: 'Question Type',
      meta : { requiresAuth: true },
      component: QuestionType,
    },
    {
      path: 'addQuestionType',
      name: 'Add Question Type',
      meta : { requiresAuth: true },
      component: AddQuestionType,
    },
    {
      path: 'editQuestionType/:id',
      name: 'Edit Question Type',
      meta : { requiresAuth: true },
      component: EditQuestionType,
    },
    {
      path: 'frequency',
      name: 'Frequency',
      meta : { requiresAuth: true },
      component: Frequency,
    },
    {
      path: 'addFrequency',
      name: 'Add Frequency',
      meta : { requiresAuth: true },
      component: AddFrequency,
    },
    {
      path: 'editFrequency/:id',
      name: 'Edit Frequency',
      meta : { requiresAuth: true },
      component: EditFrequency,
    },
    {
      path: 'category',
      name: 'Category',
      meta : { requiresAuth: true },
      component: Category,
    },
    {
      path: 'addCategory',
      name: 'Add Category',
      meta : { requiresAuth: true },
      component: AddCategory,
    },
    {
      path: 'editCategory/:id',
      name: 'Edit Category',
      meta : { requiresAuth: true },
      component: EditCategory,
    },
    {
      path: 'sub_category',
      name: 'Sub Category',
      meta : { requiresAuth: true },
      component: SubCategory,
    },
    {
      path: 'addSubCategory',
      name: 'Add Sub Category',
      meta : { requiresAuth: true },
      component: AddSubCategory,
    },
    {
      path: 'editSubCategory/:id',
      name: 'Edit Sub Category',
      meta : { requiresAuth: true },
      component: EditSubCategory,
    },
    {
      path: 'external_applications',
      name: 'External Application',
      meta : { requiresAuth: true },
      component: ExternalApplications,
    },
    {
      path: 'addExternalApplications',
      name: 'Add External Application',
      meta : { requiresAuth: true },
      component: AddExternalApplications,
    },
    {
      path: 'editExternalApplications/:id',
      name: 'Edit External Application',
      meta : { requiresAuth: true },
      component: EditExternalApplications,
    }
  ]
};

let globalTemplateMenu = {
  path: '/templates',
  component: DashboardLayout,
  redirect: '/templates/global_template',
  name: 'Templates',
  children: [
    {
      path: 'global_template',
      name: 'Template',
      meta: { requiresAuth: true},
      component: GlobalTemplates,
    },
    {
      path: 'addGlobalTemplates',
      name: 'Add Template',
      meta : { requiresAuth: true },
      component: AddGlobalTemplates,
    },
    {
      path: 'editGlobalTemplates/:id',
      name: 'Edit Template',
      meta : { requiresAuth: true },
      component: EditGlobalTemplates,
    },
    {
      path: 'globalTemplateQuestions/:id',
      name: 'Template Question',
      meta : { requiresAuth: true },
      component: GlobalTemplateQuestions,
    },
    {
      path: 'globalTemplateQuestions/:id/:flag',
      name: 'Template Question',
      meta : { requiresAuth: true },
      component: GlobalTemplateQuestions,
    },
  ]
}

let myTemplateMenu = {
  path: '/templates',
  component: DashboardLayout,
  redirect: '/templates/my_template',
  name: 'My Templates',
  children: [
    {
      path: 'my_template',
      name: 'My Template',
      meta: { requiresAuth: true},
      component: MyTemplates,
    },
    {
      path: 'addMyTemplates',
      name: 'Add My Template',
      meta : { requiresAuth: true },
      component: AddMyTemplates,
    },
    {
      path: 'editMyTemplates/:id',
      name: 'Edit My Template',
      meta : { requiresAuth: true },
      component: EditMyTemplates,
    },
    {
      path: 'myTemplateQuestions/:id',
      name: 'My Template Question',
      meta : { requiresAuth: true },
      component: MyTemplateQuestions,
    },
    
  ]
};

let customerMenu = {
  path: '/customer',
  component: DashboardLayout,
  redirect: '/customer/our_customer',
  name: 'Customer Menu',
  children: [
    {
      path: 'our_customer',
      name: 'KYC',
      meta : { requiresAuth: true },
      component: Customers
    },
    {
      path: 'our_customer/:token',
      name: 'KYC',
      meta : { requiresAuth: true },
      component: Customers
    },
    {
      path: 'our_customer/:token/:customer_id',
      name: 'KYC',
      meta : { requiresAuth: true },
      component: Customers
    },
    {
      path: 'sendCustomerForm',
      name: 'New KYC',
      meta : { requiresAuth: true },
      component: OtherCustomers
    },
    {
      path: 'editCustomers/:id',
      name: 'Edit Customers',
      meta : { requiresAuth: true },
      component: EditCustomers,
    },
    {
      path: 'addCustomers',
      name: 'Add Customers',
      meta : { requiresAuth: true },
      component: AddCustomers,
    },
    {
      path: 'sendCustomersForm/:external_customer_id/:external_application_name',
      name: 'Send KYC Template To Customers',
      meta : { requiresAuth: true },
      props: true,
      component: SendCustomersForm,
    },
    {
      path: 'sendCustomersForm/:external_customer_id/:external_application_name/:token',
      name: 'Send KYC Template To Customers',
      meta : { requiresAuth: true },
      props: true,
      component: SendCustomersForm,
    },
    {
      path: 'sendCustomersForm/:external_customer_id/:external_application_name/:token/:proposal_id',
      name: 'Send KYC Template To Customers',
      meta : { requiresAuth: true },
      props: true,
      component: SendCustomersForm,
    },
  ]
}

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home'
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  setupMenu,
  globalTemplateMenu,
  myTemplateMenu,
  customerMenu,
  // kycForm,
  {
    path: '/kyc_forms',
    component: KYCFormAuth,
    name: 'KYC Form Auth',
  },
  {
    path: '/kyc_forms/:customer_id/:customer_form_id',
    component: KYCForm,
    name: 'KYC Form',
  },
  {
    path: '/kyc_forms/:customer_id/:customer_form_id/:customer_name',
    component: KYCForm,
    name: 'KYC Form',
  },
  {
    path: '/kyc_formsV2/:customer_id/:customer_form_id',
    component: KYCFormV2,
    name: 'KYC Form',
  },
  {
    path: '/kyc_forms/submit',
    component: KYCFormSubmit,
    name: 'KYC Form Submit',
  },
  {
    path: '/kyc_forms_answer/answer/:customer_id/:customer_form_id',
    component: KYCFormAnswer,
    name: 'KYC Form Answer',
  },
  {
    path: '/kyc_forms_answer/answerV2/:customer_id/:customer_form_id',
    component: KYCFormAnswerV2,
    name: 'KYC Form Answer',
  },
  {
    path: '/kyc_forms_answer/answerV3/:customer_id/:customer_form_id',
    component: KYCFormAnswerV3,
    name: 'KYC Form Answer',
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  authPages,
];

export default routes;
